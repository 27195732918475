import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, SearchBar, Toast, Skeleton } from 'antd-mobile';
import { Container, Button } from 'react-floating-action-button';
import { PlusOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';

const getTimeFromDate = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amPM = hours >= 12 ? 'PM' : 'AM';
    const twelveHourFormat = hours % 12 || 12;

    const hours12 = twelveHourFormat.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');

    return `${hours12}:${minutesStr} ${amPM}`;
};

const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    if (date >= today) {
        if (date >= tomorrow) return date.toLocaleDateString('en-US', { weekday: 'long' });
        return getTimeFromDate(date);
    } else if (date >= yesterday) return 'Yesterday';
    if (date >= today - 6 * 24 * 60 * 60 * 1000) return date.toLocaleDateString('en-US', { weekday: 'long' });
    return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
};

const truncateNotes = (notes) => notes.length > 17 ? notes.substring(0, 17) + '...' : notes;

const ChatItem = ({ item }) => {
    return (
        <List.Item
            key={item.tag_id}
            className={`tagList`}
            prefix={''}
            description={<div style={{display:'flex', justifyContent:'space-between'}}><span><b>Contacts:</b> {item.customer_count}</span></div>}
        >
            {truncateNotes(item.tag_name || 'Unknown')}
            {item.created_at === null ? <span className='listTime'>&nbsp;</span> : <span className='listTime'>{formatDate(item.created_at)}</span>}
        </List.Item>
    );
};

const SkeletonItem = () => (
    <List.Item
        className="tagList"
        prefix={''}
        description={<Skeleton paragraph={{ rows: 1 }} />}
    >
        <Skeleton paragraph={{ rows: 1 }} style={{ width: '50%', height: 20, marginBottom:10 }} />
        <Skeleton paragraph={{ rows: 1 }} style={{ width: '30%', height: 20 }} />
    </List.Item>
);

const Tags = ({ userData }) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef(null);

    const fetchData = useCallback(async () => {
        if (!userData) return;
        setLoading(true);
        const pageSize = 20;
        const sortOrder = '';
        const sortField = '';
        try {
            const response = await fetch(`https://connectby.io:3001/api/gettags/?customerId=${userData?.user_id}&result=${pageSize}&page=${pageNumber}&searchTerm=${searchTerm}&sortorder=${sortOrder}&sortfield=${sortField}`);
            const result = await response.json();
            if (result) {
                setData(prevData => {
                    const existingIds = new Set(prevData.map(item => item.customer_id));
                    const newData = result.data.filter(item => !existingIds.has(item.customer_id));
                    const combinedData = [...prevData, ...newData];
                    combinedData.sort((a, b) => b.created - a.created);
                    return combinedData;
                });
                setHasMore(result.length === 20);
            } else {
                
                Toast.show({ content: 'Failed to fetch data', duration: 2000 });
            }
        } catch (error) {
            Toast.show({ content: `Error: ${error.message}`, duration: 2000 });
        } finally {
            setLoading(false);
        }
    }, [userData, pageNumber, searchTerm]);

    const debouncedFetchData = useMemo(() => debounce(fetchData, 800), [fetchData]);

    useEffect(() => {
        debouncedFetchData();
    }, [debouncedFetchData]);

    const debouncedSearch = useCallback(
        debounce((value) => {
            setSearchTerm(value);
            setPageNumber(1);
            setHasMore(true);
            setData([]);
        }, 800),
        []
    );

    const handleScroll = useCallback(() => {
        if (!listRef.current) return;
        const { scrollTop, clientHeight, scrollHeight } = listRef.current;

        if (scrollHeight - scrollTop <= clientHeight + 10 && hasMore && !loading) {
            setPageNumber(prevPage => prevPage + 1);
        }
    }, [hasMore, loading]);

    useEffect(() => {
        if (pageNumber > 1) debouncedFetchData();
    }, [pageNumber, debouncedFetchData]);

    useEffect(() => {
        const listElement = listRef.current;
        if (listElement) {
            listElement.addEventListener('scroll', handleScroll);
            return () => listElement.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    return (
        <div style={{height: 'calc(100vh - 150px)'}}>
            <div className='searchHeader'>
                <div className='searchLeft'>
                    <SearchBar onChange={debouncedSearch} placeholder='Search user by name, number or email' />
                </div>
            </div>
            <div ref={listRef} style={{ height: 'calc(100vh - 185px)', overflowY: 'scroll' }}>
                <List>
                    {data.map(item => (
                        <ChatItem key={item.tag_id} item={item} />
                    ))}
                    {hasMore && <div className='loading'>{Array.from({ length: 10 }).map((_, index) => <SkeletonItem key={index} />)}</div>}
                </List>
                {!hasMore && <div className='no-more'>No more data</div>}
            </div>
            <Container className="flaotingButtons">
                <Button className="floatingBtn" tooltip="Add Tag" styles={{ backgroundColor: '#075E54', color: '#FFFFFF' }} onClick={() => navigate('/add_tag')}>
                    <PlusOutlined />
                </Button>
            </Container>
        </div>
    );
};

export default Tags;