import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, SearchBar, Toast, Skeleton, DotLoading } from 'antd-mobile';
import { Container, Button } from 'react-floating-action-button';
import { PlusOutlined, UserAddOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';
import { isIOS } from 'react-device-detect';

const getTimeFromDate = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amPM = hours >= 12 ? 'PM' : 'AM';
    const twelveHourFormat = hours % 12 || 12;

    const hours12 = twelveHourFormat.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');

    return `${hours12}:${minutesStr} ${amPM}`;
};

function formatPhoneId(countryCode, phoneNumber) {
    if (countryCode.startsWith('+')) {
        countryCode = countryCode.substring(1);
    }
    return countryCode + phoneNumber;
}

const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    if (date >= today) {
        if (date >= tomorrow) return date.toLocaleDateString('en-US', { weekday: 'long' });
        return getTimeFromDate(date);
    } else if (date >= yesterday) return 'Yesterday';
    if (date >= today - 6 * 24 * 60 * 60 * 1000) return date.toLocaleDateString('en-US', { weekday: 'long' });
    return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
};

const getMessage = (message) => {
    try {
        const parsedMessage = JSON.parse(message);
        return typeof parsedMessage.body === 'string'
            ? parsedMessage.body
            : typeof parsedMessage === 'string' ? parsedMessage : JSON.stringify(parsedMessage);
    } catch {
        return typeof message === 'string' ? message : JSON.stringify(message);
    }
};

const truncateMessage = (message) => message.length > 30 ? message.substring(0, 30) + '...' : message;

const truncateNotes = (notes) => notes.length > 17 ? notes.substring(0, 17) + '...' : notes;

const SkeletonItem = () => (
    <List.Item
        className="customerList read"
        prefix={<Skeleton shape="circle" style={{ width: 40, height: 40, borderRadius: 40 }} />}
        description={<Skeleton paragraph={{ rows: 1 }} />}
    >
        <Skeleton paragraph={{ rows: 1 }} style={{ width: '50%', height: 20, marginBottom: 10 }} />
        <Skeleton paragraph={{ rows: 1 }} style={{ width: '30%', height: 20 }} />
    </List.Item>
);

const Contacts = ({ userData }) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [loadedPage, setLoadedPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef(null);


    const ChatItem = ({ item }) => {
        const message = getMessage(item.message || '');
        const truncatedMessage = truncateMessage(message);
        return (
            <List.Item
                clickable
                key={item.customer_id}
                className={`customerList ${item.msgstatus || 'read'}`}
                prefix={<div className="customer-avatar">{item.customer_name ? item.customer_name[0].toUpperCase() : ''}</div>}
                description={
                    item.msgtype === 'text' ? <span className='last-conv'>{truncatedMessage}</span> : <span className='last-conv'>{item.msgtype}</span>
                }
                onClick={() => handleClick(item)}
            >
                {truncateNotes(item.customer_name || 'Unknown')}
                {item.created === null ? <span className='listTime'>&nbsp;</span> : <span className='listTime'>{formatDate(item.created)}</span>}
            </List.Item>
        );
    };

    const handleClick = (item) => {
        navigate('/chats', { state: { customerData: item } });
    }


    useEffect(() => {
        function newchat() {
            let ws = new WebSocket('wss://connectby.io:3001');
            ws.onopen = () => {
                console.log('WebSocket connected');
            };

            ws.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);

                    if (data?.user_data?.customer_id === userData?.user_id) {
                        console.log('Received JSON data:', data);
                        if (data.entry && data.entry.length > 0 && data.entry[0].changes) {
                            const messagesChange = data.entry[0].changes.find(change => change.field === 'messages');
                            if (messagesChange && messagesChange.value.messages) {
                                const receivedMessage = messagesChange.value.messages[0];
                                const messagesNumber = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.wa_id;
                                const messagesName = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.profile?.name;
                                let activephone = 'notactive';
                                if (receivedMessage.type === 'text') {

                                    if (activephone === 'notactive') {
                                        if (data.user_data.is_user === 'new') {
                                            const newUserData = {
                                                customer_id: data.user_data.user_id,
                                                country_code: messagesNumber.substring(0, 2),
                                                customer_phone: messagesNumber.substring(2),
                                                customer_name: messagesName,
                                                created: Math.floor(Date.now() / 1000).toString(),
                                                message: receivedMessage?.text?.body,
                                                msgtype: receivedMessage.type,
                                                msgstatus: "unread"
                                            };
                                            setData((prevData) => [newUserData, ...prevData]);
                                        } else {
                                            setData((PrevData) => {
                                                const updatedData = PrevData.map((userdata) => {
                                                    if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                        return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: receivedMessage?.text?.body, msgstatus: "unread", msgtype: receivedMessage.type };
                                                    }
                                                    return userdata;
                                                });
                                                const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                                const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                                return [movedUser, ...restOfUsers];
                                            });
                                        }
                                    } else {
                                        if (activephone === messagesNumber) {

                                        } else {
                                            setData((PrevData) => {
                                                const updatedData = PrevData.map((userdata) => {
                                                    if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                        return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: receivedMessage?.text?.body, msgstatus: "unread", msgtype: receivedMessage.type };
                                                    }
                                                    return userdata;
                                                });
                                                const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                                const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                                return [movedUser, ...restOfUsers];
                                            });
                                        }
                                    }
                                }
                                if (receivedMessage.type === 'image') {
                                    const messagesNumber = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.wa_id;

                                    if (activephone === 'notactive') {
                                        setData((PrevData) => {
                                            const updatedData = PrevData.map((userdata) => {
                                                if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                    return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: 'image', msgstatus: "unread", msgtype: receivedMessage.type };
                                                }
                                                return userdata;
                                            });
                                            const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                            const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                            return [movedUser, ...restOfUsers];
                                        });
                                    } else {
                                        if (activephone === messagesNumber) {

                                        } else {
                                            setData((PrevData) => {
                                                const updatedData = PrevData.map((userdata) => {
                                                    if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                        return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: 'image', msgstatus: "unread", msgtype: receivedMessage.type };
                                                    }
                                                    return userdata;
                                                });
                                                const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                                const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                                return [movedUser, ...restOfUsers];
                                            });
                                        }
                                    }

                                }
                                if (receivedMessage.type === 'video') {
                                    const messagesNumber = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.wa_id;

                                    if (activephone === 'notactive') {
                                        setData((PrevData) => {
                                            const updatedData = PrevData.map((userdata) => {
                                                if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                    return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: 'video', msgstatus: "unread", msgtype: receivedMessage.type };
                                                }
                                                return userdata;
                                            });
                                            const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                            const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                            return [movedUser, ...restOfUsers];
                                        });
                                    } else {
                                        if (activephone === messagesNumber) {

                                        } else {
                                            setData((PrevData) => {
                                                const updatedData = PrevData.map((userdata) => {
                                                    if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                        return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: 'video', msgstatus: "unread", msgtype: receivedMessage.type };
                                                    }
                                                    return userdata;
                                                });
                                                const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                                const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                                return [movedUser, ...restOfUsers];
                                            });
                                        }
                                    }
                                }
                                if (receivedMessage.type === 'document') {
                                    const messagesNumber = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.wa_id;

                                    if (activephone === 'notactive') {
                                        setData((PrevData) => {
                                            const updatedData = PrevData.map((userdata) => {
                                                if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                    return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: 'document', msgstatus: "unread", msgtype: receivedMessage.type };
                                                }
                                                return userdata;
                                            });
                                            const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                            const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                            return [movedUser, ...restOfUsers];
                                        });
                                    } else {
                                        if (activephone === messagesNumber) {

                                        } else {
                                            setData((PrevData) => {
                                                const updatedData = PrevData.map((userdata) => {
                                                    if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                        return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: 'document', msgstatus: "unread", msgtype: receivedMessage.type };
                                                    }
                                                    return userdata;
                                                });
                                                const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                                const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                                return [movedUser, ...restOfUsers];
                                            });
                                        }
                                    }

                                }
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                    console.log('Received non-JSON data:', event.data);
                }
            };

            ws.onclose = () => {
                console.log('WebSocket closed');
                setTimeout(() => {
                    newchat();
                }, 3000);
            };
        }
        newchat();
    }, []);

    const fetchData = useCallback(async () => {
        if (!userData) return;
        setLoading(true);
        
        try {
            const response = await fetch(`https://connectby.io:3001/api/usersphone/?customerId=${userData.user_id}&phoneNoId=${userData.phonenumberid}&result=20&page=${pageNumber}&searchTerm=${searchTerm}`);
            const result = await response.json();
            if (result) {
                setData(prevData => {
                    const existingIds = new Set(prevData.map(item => item.customer_id));
                    const newData = result.filter(item => !existingIds.has(item.customer_id));
                    const combinedData = [...prevData, ...newData];
                    combinedData.sort((a, b) => b.created - a.created);
                    return combinedData;
                });
                setHasMore(result.length === 20);
                setLoadedPage(pageNumber);
                setLoading(false);
            } else {
                Toast.show({ content: 'Failed to fetch data', duration: 2000 });
            }
        } catch (error) {
            Toast.show({ content: `Error: ${error.message}`, duration: 2000 });
        } finally {

        }
    }, [userData, pageNumber, searchTerm]);

    const debouncedFetchData = useMemo(() => debounce(fetchData, 100), [fetchData]);

    useEffect(() => {
        if (pageNumber > 1) {
            debouncedFetchData();
        }
        return () => {
            debouncedFetchData.cancel();
        };
    }, [pageNumber, debouncedFetchData]);

    useEffect(() => {
        debouncedFetchData();
    }, [debouncedFetchData]);

    const handleLoadMore = useCallback(() => {
        if (!loading && hasMore) {
            setPageNumber(prevPage => prevPage + 1);
        }
    }, [hasMore, loading]);

    const debouncedSearch = useMemo(() => debounce((value) => {
        setSearchTerm(value);
        setPageNumber(1);
        setHasMore(true);
        setData([]);
    }, 800), []);

    return (
        <div style={{ height: 'calc(100vh - 150px)', display:'block'}}>
            <div className='searchHeader'>
                <div className='searchLeft'>
                    <SearchBar onChange={debouncedSearch} placeholder='Search user by name, number or email' />
                </div>
            </div>
            <div ref={listRef} style={{ height: 'calc(100vh - 185px)', overflowY: 'scroll' }}>
                <List>
                    {data.map(item => (
                        <ChatItem key={item.customer_id} item={item} />
                    ))}
                    {hasMore && loading && pageNumber === 1 && <div className='loading'>{Array.from({ length: 10 }).map((_, index) => <SkeletonItem key={index} />)}</div>}
                </List>
                {hasMore && (
                    loading ? (
                        <div className='dotloading' style={{ paddingBottom: 100 }}>
                            <DotLoading />
                        </div>
                    ) : (
                        <div style={{ paddingBottom: 100 }}>
                            <Button onClick={handleLoadMore} className="loadMoreBtn" type='primary' size='mini' color='primary'>
                                Load More
                            </Button>
                        </div>
                    )
                )}
                {!hasMore && <div className='no-more' style={{ paddingBottom: 100 }}>No more data</div>}
            </div>
            <Container className="flaotingButtons">
                <Button className="addContactBtn" onClick={() => navigate('/add_contact')}
                    tooltip="Add Contacts"
                    styles={{ backgroundColor: '#075E54', color: '#FFFFFF' }}>
                    <UserAddOutlined />
                </Button>
                <Button className="bulkAddContactsBtn" onClick={() => navigate('/bulk_add_contact')}
                    tooltip="Bulk add Contacts"
                    styles={{ backgroundColor: '#075E54', color: '#FFFFFF' }}>
                    <UsergroupAddOutlined />
                </Button>
                <Button className="floatingBtn" styles={{ backgroundColor: '#075E54', color: '#FFFFFF' }}>
                    <PlusOutlined />
                </Button>
            </Container>
        </div>
    );
};

export default Contacts;