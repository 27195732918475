import React, { useState, useEffect } from 'react';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Button, DatePicker, Toast } from 'antd-mobile';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { MdOutlineSend, MdOutlineDoneAll } from "react-icons/md";
import { AiFillNotification } from "react-icons/ai";
import { ClipLoader } from 'react-spinners';
import { format, parseISO } from 'date-fns';
const now = new Date();
const sevenDaysAgo = new Date(now);
sevenDaysAgo.setDate(now.getDate() - 7);

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale, BarElement);

const Home = ({ userData }) => {
    const [loading, setLoading] = useState(true);
    const [visibleDatePicker, setVisibleDatePicker] = useState(false);
    const [visibleEndDatePicker, setVisibleEndDatePicker] = useState(false);
    const [sData, setSData] = useState([]);
    const [dData, setDData] = useState([]);
    const [rData, setRData] = useState([]);
    const [fData, setFData] = useState([]);
    const [xLabels, setXLabels] = useState([]);
    const [dashboardData, setDashboardData] = useState({
        sentCount: 0,
        readCount: 0,
        deliveredCount: 0,
        failedCount: 0,
    });

    const [startDate, setStartDate] = useState(sevenDaysAgo.toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(now.toISOString().split('T')[0]);

    useEffect(() => {
        if (!userData) return;

        const fetchData = async () => {
            try {
                const response1 = await fetch(`https://connectby.io:3001/api/getdashborddetails?phone_number_id=${userData?.phonenumberid}`);
                const data1 = await response1.json();
                if (data1 && data1.whatsapp_counts) {
                    setDashboardData({
                        sentCount: data1.whatsapp_counts.sent_count || 0,
                        readCount: data1.whatsapp_counts.read_count || 0,
                        deliveredCount: data1.whatsapp_counts.delivered_count || 0,
                        failedCount: data1.whatsapp_counts.failed_count || 0,
                    });
                }

                const response2 = await fetch(`https://connectby.io:3001/api/getdashboardgraphdetails?phone_number_id=${userData?.phonenumberid}`);
                const data2 = await response2.json();
                if (data2 && data2.daily_counts.length !== 0) {
                    const sdatas = [];
                    const rdatas = [];
                    const ddatas = [];
                    const fdatas = [];
                    const labels = [];

                    data2.daily_counts.forEach((counts) => {
                        const countDate = new Date(counts.date).toISOString().split('T')[0];
                        if (countDate >= startDate && countDate <= endDate) {
                            labels.push(format(parseISO(counts.date), 'dd-MM'));
                            sdatas.push(counts.sent_count + counts.delivered_count + counts.read_count);
                            rdatas.push(counts.read_count);
                            ddatas.push(counts.delivered_count + counts.read_count);
                            fdatas.push(counts.failed_count);
                        }
                    });
                    setXLabels(labels);
                    setSData(sdatas);
                    setRData(rdatas);
                    setDData(ddatas);
                    setFData(fdatas);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [userData, startDate, endDate]);

    const chartData1 = {
        labels: ['Sent', 'Delivered', 'Read'],
        datasets: [{
            data: [dashboardData.sentCount + dashboardData.deliveredCount + dashboardData.readCount, dashboardData.deliveredCount + dashboardData.readCount, dashboardData.readCount],
            backgroundColor: ['rgba(7,94,84, 0.2)', 'rgba(18,140,126, 0.2)', 'rgba(37,211,102, 0.2)', 'rgba(0, 0, 0, 0.2)'],
            borderColor: ['rgba(7,94,84, 1)', 'rgba(18,140,126, 1)', 'rgba(37,211,102, 1)', 'rgba(0, 0, 0, 1)'],
            borderWidth: 1,
        }],
    };

    const chartOptions1 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => `${tooltipItem.label}: ${tooltipItem.raw}`,
                },
            },
        },
    };

    const chartData2 = {
        labels: xLabels,
        datasets: [
            {
                label: 'Sent',
                data: sData,
                backgroundColor: 'rgba(7,94,84, 0.2)',
                borderColor: 'rgba(7,94,84, 1)',
                borderWidth: 1,
            },
            {
                label: 'Delivered',
                data: dData,
                backgroundColor: 'rgba(18,140,126, 0.2)',
                borderColor: 'rgba(18,140,126, 1)',
                borderWidth: 1,
            },
            {
                label: 'Read',
                data: rData,
                backgroundColor: 'rgba(37,211,102, 0.2)',
                borderColor: 'rgba(37,211,102, 1)',
                borderWidth: 1,
            },
            // {
            //     label: 'Failed',
            //     data: fData,
            //     backgroundColor: 'rgba(0, 0, 0, 0.2)',
            //     borderColor: 'rgba(0, 0, 0, 1)',
            //     borderWidth: 1,
            // },
        ],
    };

    const chartOptions2 = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => `${tooltipItem.dataset.label}: ${tooltipItem.raw}`,
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Dates',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Report',
                },
                min: 0,
                ticks: {
                    stepSize: 10,
                    callback: function (value) {
                        if (value % 10 === 0) {
                            return value;
                        }
                    },
                },
            },
        },
    };

    const handleDatePickerConfirm = (date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        setStartDate(formattedDate);
        setVisibleDatePicker(false);
        setVisibleEndDatePicker(true);
    };

    const handleEndDatePickerConfirm = (date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        setEndDate(formattedDate);
        setVisibleEndDatePicker(false);
        Toast.show(`Selected from ${startDate} to ${endDate}`);
    };

    const handleCancel = () => {
        setVisibleEndDatePicker(false);
        setVisibleDatePicker(true);
    };

    return (
        <>
            {loading && (
                <div className="loading-spinner">
                    <ClipLoader color="#075E54" loading={loading} size={100} />
                </div>
            )}
            <div className='chartMainDiv'>
                <div className='gridRowDataOne'>
                    <div className='cards sentCard'>
                        <div style={{ color: '#fff', fontSize: '20px', fontWeight: 'bold' }}>
                            {dashboardData.sentCount + dashboardData.deliveredCount + dashboardData.readCount + dashboardData.failedCount}
                        </div>
                        <div style={{ fontSize: '16px' }}>Attempted</div>
                        <div style={{ position: 'absolute', bottom: 25, right: 15 }} className='icons'>
                            <AiFillNotification style={{ fontSize: '40px', color: '#fff' }} />
                        </div>
                    </div>
                    <div className='cards deliveredCard'>
                        <div style={{ color: '#fff', fontSize: '20px', fontWeight: 'bold' }}>
                            {dashboardData.sentCount + dashboardData.deliveredCount + dashboardData.readCount}
                        </div>
                        <div style={{ fontSize: '16px' }}>Sent</div>
                        <div style={{ position: 'absolute', bottom: 25, right: 15 }} className='icons'>
                            <MdOutlineSend style={{ fontSize: '40px', color: '#fff' }} />
                        </div>
                    </div>
                    
                </div>
                <div className='gridRowData'>
                    <div className='cards readCard'>
                        <div style={{ color: '#fff', fontSize: '20px', fontWeight: 'bold' }}>
                            {dashboardData.deliveredCount + dashboardData.readCount}
                        </div>
                        <div style={{ fontSize: '16px' }}>Delivered</div>
                        <div style={{ position: 'absolute', bottom: 25, right: 15 }} className='icons'>
                            <MdOutlineDoneAll style={{ fontSize: '40px', color: '#fff' }} />
                        </div>
                    </div>
                    <div className='cards failedCard'>
                        <div style={{ color: '#fff', fontSize: '20px', fontWeight: 'bold' }}>{dashboardData.readCount}</div>
                        <div style={{ fontSize: '16px' }}>Read</div>
                        <div style={{ position: 'absolute', bottom: 25, right: 15 }} className='icons'>
                            <MdOutlineDoneAll style={{ fontSize: '40px', color: '#fff' }} />
                        </div>
                    </div>
                </div>
                <div className='chartDiv'>
                    <Doughnut data={chartData1} options={chartOptions1} />
                </div>
                <div className='chartDiv'>
                    <div className='date-picker-container'>
                        <Button onClick={() => setVisibleDatePicker(true)}>
                            Showing: {startDate} to {endDate}
                        </Button>
                        <DatePicker
                            cancelText='Cancel'
                            confirmText='Next'
                            visible={visibleDatePicker}
                            title='Select From Date'
                            max={sevenDaysAgo}
                            onClose={() => setVisibleDatePicker(false)}
                            onConfirm={handleDatePickerConfirm}
                        />
                        <DatePicker
                            cancelText='Prev'
                            confirmText='Done'
                            visible={visibleEndDatePicker}
                            title='Select To Date'
                            max={now}
                            onCancel={handleCancel}
                            onClose={() => setVisibleEndDatePicker(false)}
                            onConfirm={handleEndDatePickerConfirm}
                        />
                    </div>
                    <div className='bar-chart'>
                        <Bar data={chartData2} options={chartOptions2} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;