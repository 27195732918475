import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, SearchBar, Toast, Skeleton } from 'antd-mobile';
import { Container, Button } from 'react-floating-action-button';
import { PlusOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';

const truncateNotes = (notes) => notes.length > 17 ? notes.substring(0, 17) + '...' : notes;

const TemplateItem = ({ item }) => {
    return (
        <List.Item
            key={item.id}
            className={`templateList`}
            prefix={''}
            description={<div style={{display:'flex', justifyContent:'space-between'}}><span><b>Status:</b> {item.status}</span><span><b>Language:</b> {item.language}</span></div>}
        >
            {truncateNotes(item.name.charAt(0).toUpperCase() + item.name.slice(1).replace(/_/g, ' ') || 'Unknown')}
        </List.Item>
    );
};

const SkeletonItem = () => (
    <List.Item
        className="templateList read"
        prefix={''}
        description={<Skeleton paragraph={{ rows: 1 }} />}
    >
        <Skeleton paragraph={{ rows: 1 }} style={{ width: '50%', height: 20, marginBottom:10 }} />
        <Skeleton paragraph={{ rows: 1 }} style={{ width: '30%', height: 20 }} />
    </List.Item>
);

const Template = ({ userData }) => {
    const navigate = useNavigate();
    const [templateData, setTemplateData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const listRef = useRef(null);

    const fetchData = useCallback(async () => {
        if (!userData) return;
        setLoading(true);
        try {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.whatsappAccessToken,
                    'wabaid': userData?.wabaid
                }
            };
            const response = await fetch('https://connectby.io:3001/api/get-template', requestOptions);
            const result = await response.json();
            if (result && result?.success) {
                const templates = result?.responseData.data;
                setTemplateData(prevData => {
                    const existingIds = new Set(prevData.map(item => item.id));
                    const newData = templates.filter(item => !existingIds.has(item.id));
                    return [...prevData, ...newData];
                });
            } else {
                Toast.show({ content: 'Failed to fetch data', duration: 2000 });
            }
        } catch (error) {
            Toast.show({ content: `Error: ${error.message}`, duration: 2000 });
        } finally {
            setLoading(false);
        }
    }, [userData]);

    const debouncedFetchData = useMemo(() => debounce(fetchData, 800), [fetchData]);

    useEffect(() => {
        debouncedFetchData();
    }, [debouncedFetchData]);

    const handleSearch = useCallback((value) => {
        setSearchQuery(value.toLowerCase());
    }, []);

    const filteredData = templateData.filter(item =>
        item.name.toLowerCase().includes(searchQuery)
    );


    return (
        <div style={{height: 'calc(100vh - 150px)'}}>
            <div className='searchHeader'>
                <div className='searchLeft'>
                    <SearchBar onChange={handleSearch} placeholder='Search Template by name' />
                </div>
            </div>
            <div ref={listRef} style={{ height: 'calc(100vh - 185px)', overflowY: 'scroll' }}>
                <List>
                    {filteredData.map(item => (
                        <TemplateItem key={item.id} item={item} />
                    ))}
                    {loading && <div className='loading'>{Array.from({ length: 10 }).map((_, index) => <SkeletonItem key={index} />)}</div>}
                </List>
                {!loading && <div className='no-more'>No more data</div>}
            </div>
            {/* <Container className="flaotingButtons" >
                <Button className="floatingBtn" tooltip="Add Template" styles={{ backgroundColor: '#075E54', color: '#FFFFFF' }} onClick={() => navigate('/add_template')}>
                    <PlusOutlined />
                </Button>
            </Container> */}
        </div>
    );
};

export default Template;