import React, { useState, useRef, useEffect } from 'react';
import {
    Form,
    Input,
    Button,
    TextArea,
    DatePicker,
    Picker,
    Toast,
    DotLoading
} from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Select as AntdSelect } from 'antd';

const { Option } = AntdSelect;

const AddContact = ({ userData }) => {
    const navigate = useNavigate();
    const [visibleGenderPicker, setVisibleGenderPicker] = useState(false);
    const [gender, setGender] = useState('');
    const [tagsData, setTagsData] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const datePickerRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [phoneValue, setPhoneValue] = useState('');
    const [countryCode, setCountryCode] = useState('');

    useEffect(() => {
        getTags();
    }, []);

    const getTags = async () => {
        const userId = userData?.user_id;
        fetch('https://connectby.io:3001/api/get-tags', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_id: userId })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setTagsData(data);
            })
            .catch(error => {
                console.error('Error fetching tags:', error);
            });
    };

    const onPhoneChange = (value, country) => {
        setPhoneValue(value);
        setCountryCode(country.dialCode);
    };

    const onFinish = async (formData) => {
        setLoading(true);
        const dobDate = formData.customer_dob ? new Date(formData.customer_dob) : null;
        const formattedDOB = dobDate ? `${dobDate.getFullYear()}-${(dobDate.getMonth() + 1).toString().padStart(2, '0')}-${dobDate.getDate().toString().padStart(2, '0')}` : '';
        const numberwithPlus = `+${phoneValue}`;
        const numberWithoutCountryCode = numberwithPlus.replace(`+${countryCode}`, '');
        const body = {
            crm_user_id: userData?.user_id,
            country_code: countryCode,
            customer_name: formData.customer_name || '',
            customer_phone: numberWithoutCountryCode || '',
            customer_email: formData.customer_email || '',
            customer_gender: gender || '',
            customer_dob: formattedDOB,
            tags: selectedTags || [],
            note: formData.note || ''
        };
        try {
            const response = await fetch('https://connectby.io:3001/api/create-customer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            if (response.ok) {
                Toast.show({
                    icon: 'success',
                    content: 'Contact added successfully.',
                });
                navigate('/contacts');
            } else {
                console.log(response);
                Toast.show({
                    icon: 'fail',
                    content: 'An error occurred. Please try again.',
                });
            }
        } catch (error) {
            console.error('Error:', error);
            Toast.show({
                icon: 'fail',
                content: 'An error occurred. Please try again.',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ padding: '15px' }}>
            <Form
                onFinish={onFinish}
                footer={
                    <Button
                        block
                        type="submit"
                        color="primary"
                        size="large"
                        style={{ width: '100%' }}
                    >
                        {loading ? <DotLoading /> : <><PlusOutlined /> Add Contact</>}
                    </Button>
                }
            >
                <Form.Item
                    name="customer_name"
                    label="Customer Name"
                    rules={[{ required: true, message: 'Please enter the customer name' }]}
                >
                    <Input placeholder="Enter customer name" />
                </Form.Item>

                <Form.Item
                    name="customer_phone"
                    label="Customer Phone"
                    rules={[{ required: true, message: 'Please enter the customer phone number' }]}
                >
                    <PhoneInput
                        country={'in'}
                        value={phoneValue}
                        onChange={onPhoneChange}
                        placeholder="Enter customer phone"
                        inputStyle={{ width: '100%', height: '40px', borderRadius: '4px', borderColor: '#d9d9d9' }}
                        buttonStyle={{ borderColor: '#d9d9d9' }}
                        dropdownStyle={{ borderColor: '#d9d9d9' }}
                        containerClass="phone-input-container"
                        inputClass="phone-input"
                        enableSearch
                    />
                </Form.Item>

                <Form.Item
                    name="customer_email"
                    label="Customer Email"
                    rules={[{ required: false, message: 'Please enter the customer email' }]}
                >
                    <Input placeholder="Enter customer email" />
                </Form.Item>

                <Form.Item
                    name="customer_gender"
                    label="Customer Gender"
                    rules={[{ required: false, message: 'Please select the customer gender' }]}
                    trigger="onConfirm"
                    onClick={() => setVisibleGenderPicker(true)}
                >
                    <Input value={gender} readOnly placeholder="Select gender" />
                    <Picker
                        visible={visibleGenderPicker}
                        onClose={() => setVisibleGenderPicker(false)}
                        onConfirm={(value) => {
                            setGender(value[0]);
                            setVisibleGenderPicker(false);
                        }}
                        columns={[['Male', 'Female', 'Other']]}
                    />
                </Form.Item>

                <Form.Item
                    name="tags"
                    label="Customer Tags"
                    rules={[{ required: false, message: 'Please select tags' }]}
                >
                    <div>
                        <AntdSelect
                            mode="tags"
                            style={{ width: '100%' }}
                            value={selectedTags}
                            placeholder="Select tags"
                            onChange={setSelectedTags}
                        >
                            {tagsData.map(tags => (
                                <Option key={tags.tag_id} value={tags.tag_id}>
                                    {tags.tag_name}
                                </Option>
                            ))}
                        </AntdSelect>
                    </div>
                </Form.Item>

                <Form.Item
                    name="customer_dob"
                    label="Customer DOB"
                    rules={[{ required: false, message: 'Please select the customer date of birth' }]}
                    trigger="onConfirm"
                    onClick={() => datePickerRef.current?.open()}
                >
                    <DatePicker
                        ref={datePickerRef}
                        min={dayjs().subtract(100, 'year').toDate()}
                        max={dayjs().toDate()}
                        format="YYYY-MM-DD"
                    >
                        {value => value ? dayjs(value).format('YYYY-MM-DD') : 'Select date of birth'}
                    </DatePicker>
                </Form.Item>

                <Form.Item
                    name="note"
                    label="Note"
                >
                    <TextArea placeholder="Enter any notes" />
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddContact;