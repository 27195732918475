import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Form,
    Input,
    Button,
    Toast,
    DotLoading
} from 'antd-mobile';
import { PlusOutlined } from '@ant-design/icons';

const AddTag = ({ userData }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const response = await fetch('https://connectby.io:3001/api/create-new-tag', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    crm_user_id: userData?.user_id,
                    tag_name: values?.tag_name || '',
                }),
            });

            if (response.ok) {
                Toast.show({
                    icon: 'success',
                    content: 'Tag added successfully.',
                });
                navigate('/tags');
            } else {
                console.log(response);
                Toast.show({
                    icon: 'fail',
                    content: 'Tag name already exist. Use Different Tag name',
                });
            }
        } catch (error) {
            console.error('Error:', error);
            console.log(error);
            Toast.show({
                icon: 'fail',
                content: 'An error occurred. Please try again.',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ padding: '15px' }}>
            <Form
                onFinish={onFinish}
                footer={
                    <Button block type='submit' color='primary' size='large' style={{ margin: '-15px', width: 'calc(100% + 30px)' }}>
                        {loading ? <DotLoading /> : <><PlusOutlined /> Add Tag</>}
                    </Button>
                }
            >
                <Form.Item
                    name="tag_name"
                    label="Tag Name"
                    rules={[{ required: true, message: 'Please enter the tag name' }]}
                >
                    <Input placeholder="Please enter tag name" />
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddTag;