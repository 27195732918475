import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import {
    Form,
    Input,
    Button,
    TextArea,
    DatePicker,
    Picker,
    Toast,
    DotLoading,
    Radio
} from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Select as AntdSelect } from 'antd';

const { Option } = AntdSelect;

const AddCampaign = ({ userData }) => {
    const navigate = useNavigate();
    const [tagsData, setTagsData] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [templatedata, setTemplatedata] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState([]);
    const [isSchduleEnabled, setIsSchduleEnabled] = useState('enable');
    const [scheduling, setScheduling] = useState('now');
    const now = dayjs().toDate();
    const datePickerRef = useRef(null);
    const handleSchedule = (value) => {
        setScheduling(value);
    };
    const handleRadioChange = (value) => {
        setIsSchduleEnabled(value);
    };

    useEffect(() => {
        getTags();
        getTemplates();
    }, [userData]);

    const getTags = async () => {
        if (!userData) return;
        const userId = userData?.user_id;
        fetch('https://connectby.io:3001/api/get-tags', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_id: userId })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setTagsData(data);
            })
            .catch(error => {
                console.error('Error fetching tags:', error);
            });
    };

    const getTemplates = async () => {
        if (!userData) return;
        const templatedataUrl = `https://connectby.io:3001/api/get-template`;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'whatsappaccesstoken': userData?.whatsappAccessToken,
                'wabaid': userData?.wabaid
            }
        };
        fetch(templatedataUrl, requestOptions)
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();
            })
            .then((body) => {
                if (body.success === true) {
                    setTemplatedata(body.responseData.data);
                } else {
                    console.error('Error: Success is not true');
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const onFinish = async (formData) => {
        const template = _.cloneDeep(selectedTemplate);
        const templateName = template.name;
        const templateLanguage = template.language;
        console.log(template);
        let datacomponent = [];

        // setLoading(true);
        // const body = {
        //     crm_user_id: userData?.user_id,
        //     country_code: countryCode,
        //     customer_name: formData.customer_name || '',
        //     customer_phone: numberWithoutCountryCode || '',
        //     customer_email: formData.customer_email || '',
        //     customer_dob: formattedDOB,
        //     tags: selectedTags || [],
        //     note: formData.note || ''
        // };
        // try {
        //     const response = await fetch('https://connectby.io:3001/api/create-customer', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify(body),
        //     });

        //     if (response.ok) {
        //         Toast.show({
        //             icon: 'success',
        //             content: 'Contact added successfully.',
        //         });
        //         navigate('/contacts');
        //     } else {
        //         console.log(response);
        //         Toast.show({
        //             icon: 'fail',
        //             content: 'An error occurred. Please try again.',
        //         });
        //     }
        // } catch (error) {
        //     console.error('Error:', error);
        //     Toast.show({
        //         icon: 'fail',
        //         content: 'An error occurred. Please try again.',
        //     });
        // } finally {
        //     setLoading(false);
        // }
    };

    const handleTemplateSelect = (templateId) => {
        setSelectedTemplateId(templateId);
        const selectedTemplateData = templatedata.find(template => template.id === templateId);
        setSelectedTemplate(selectedTemplateData);
        console.log(selectedTemplateData);
    };

    return (
        <div style={{ height: 'calc(100vh - 150px)', overflow: 'scroll', padding: '15px' }}>
            <Form
                onFinish={onFinish}
                footer={
                    <Button
                        block
                        type="submit"
                        color="primary"
                        size="large"
                        style={{ width: '100%' }}
                    >
                        {loading ? <DotLoading /> : <><PlusOutlined /> Create Campaign</>}
                    </Button>
                }
            >
                <Form.Item
                    name="campaign_name"
                    label="Campaign Name"
                    rules={[{ required: true, message: 'Please enter campaign name' }]}
                >
                    <Input placeholder="Enter customer name" />
                </Form.Item>
                <Form.Item label="Enable/Disable Re-attempt of failed messages">
                    <Radio.Group onChange={handleRadioChange} defaultValue={userData?.schduleenable === '1' ? 'enable' : 'disable'}>
                        <Radio value="enable">Enable&nbsp;</Radio>
                        <Radio value="disable">Disable</Radio>
                    </Radio.Group>
                    <p style={{ fontSize: 10 }}>(<b>Note: </b>By enabling this re-attempt of failed messages will occur after 24 hours.)</p>
                </Form.Item>
                <Form.Item
                    name="tag_id"
                    label="Tags"
                    rules={[{ required: false, message: 'Please select tags' }]}
                >
                    <div>
                        <AntdSelect
                            mode="multiple"
                            style={{ width: '100%' }}
                            value={selectedTags}
                            placeholder="Select tags"
                            onChange={setSelectedTags}
                        >
                            {tagsData.map(tags => (
                                <Option key={tags.tag_id} value={tags.tag_id}>
                                    {tags.tag_name}
                                </Option>
                            ))}
                        </AntdSelect>
                    </div>
                </Form.Item>

                <Form.Item
                    name="template_id"
                    label="Template"
                    rules={[{ required: false, message: 'Please select template' }]}
                >
                    <div>
                        <AntdSelect
                            style={{ width: '100%' }}
                            value={selectedTemplateId}
                            placeholder="Select template"
                            onChange={handleTemplateSelect}
                        >
                            {templatedata.map(template => (
                                template.status === 'APPROVED' && (
                                    <Option key={template.id} value={template.id}>{`${template.name} (${template.language})`}</Option>
                                )
                            ))}
                        </AntdSelect>
                    </div>
                </Form.Item>
                <Form.Item name="scheduling" label="Scheduling">
                    <Radio.Group onChange={handleSchedule} defaultValue='now'>
                        <Radio value="now">Immediately&nbsp;</Radio>
                        <Radio value="schedule">Custom date</Radio>
                    </Radio.Group>
                </Form.Item>
                {scheduling === 'schedule' && (
                    <Form.Item
                        name="schedule_datetime"
                        label="Schedule Date & Time"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a schedule date and time',
                            },
                        ]}
                        trigger="onConfirm"
                        onClick={() => datePickerRef.current?.open()}
                    >
                        <DatePicker
                            ref={datePickerRef}
                            precision='minute'
                            onConfirm={(val) => console.log(dayjs(val).format('YYYY-MM-DD HH:mm'))}
                            min={now}
                        >
                            {value =>
                                value ? dayjs(value).format('YYYY-MM-DD HH:mm') : 'Select Date & Time'
                            }
                        </DatePicker>
                    </Form.Item>
                )}
            </Form>
        </div>
    );
};

export default AddCampaign;