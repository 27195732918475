import React, { useContext, useState } from "react";
import { Toast, DotLoading, Button } from 'antd-mobile';
import {
  BoldLink,
  BoxContainer,
  FormContainer,
  Input,
  LineText,
  MutedLink,
  SubmitButton,
} from "./common";
import { Marginer } from "../marginer";
import { AccountContext } from './accountContext';
import { AiOutlineLogin } from "react-icons/ai";
import { redirect, useNavigate } from 'react-router-dom';
import {EyeInvisibleOutline, EyeOutline} from 'antd-mobile-icons';

export function LoginForm({props, setIsLoggedIn}) {
  const navigate = useNavigate();
  const { switchToSignup } = useContext(AccountContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch('https://connectby.io:3001/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: username, password: password }),
      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const data = await response.json();
      Toast.show({ content: 'Login successful', duration: 2000 });
      localStorage.setItem("redirectId", data?.userData[0]?.user_id);
      window.location.reload();
    } catch (error) {
      Toast.show({ content: error.message, duration: 2000 });
    } finally {
      setLoading(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <BoxContainer style={{ zIndex: 10 }}>
      <form onSubmit={handleLogin} style={{width: '-webkit-fill-available'}}>
        <FormContainer>
          <Input 
            type="email" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            placeholder="Email" 
          />
          <div style={{position: 'relative'}}>
          <Input 
            type={showPassword ? 'text': 'password'} 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            placeholder="Password" 
          />
          <Button
        onClick={togglePasswordVisibility}
        style={{
          position: 'absolute',
          right: 0,
          top: -8,
          height: '100%',
          border: 'none',
          background: 'transparent',
        }}
      >
        {showPassword ? <EyeOutline /> : <EyeInvisibleOutline />}
      </Button>
      </div>
        </FormContainer>
        <Marginer direction="vertical" margin={10} />
        <MutedLink href="#">Forget your password?</MutedLink>
        <Marginer direction="vertical" margin="1.6em" />
        <SubmitButton type="submit" disabled={loading}>
          <span style={{ fontSize: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {loading ? <DotLoading /> : <><AiOutlineLogin />&nbsp;&nbsp;Login</>}
          </span>
        </SubmitButton>
        <Marginer direction="vertical" margin="5px" />
        <LineText>
          Don't have an account?{" "}
          <BoldLink onClick={switchToSignup} href="#">
            Signup
          </BoldLink>
        </LineText>
      </form>
    </BoxContainer>
  );
}