import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, Switch, Avatar } from 'antd-mobile';
import {
    UsergroupAddOutlined,
    DollarOutlined,
    MoonOutlined,
    BellOutlined,
    QuestionCircleOutlined,
    StarOutlined,
    LogoutOutlined,
    MobileOutlined
} from '@ant-design/icons';

const Profile = ({ userData, enableDarkMode, setEnableDarkMode, setIsLoggedIn }) => {
    const navigate = useNavigate();
    useEffect(() => {
        const storedDarkMode = localStorage.getItem('enableDarkMode');
        if (storedDarkMode !== null) {
            setEnableDarkMode(JSON.parse(storedDarkMode));
        }
    }, [setEnableDarkMode]);

    const handleDarkModeChange = (mode) => {
        localStorage.setItem('enableDarkMode', JSON.stringify(mode));
        setEnableDarkMode(mode);
    };

    return (
        <div className='profileMainDiv' style={{paddingBottom: 150}}>
            {/* Profile Section */}
            <List header='PROFILE'>
                <List.Item
                    prefix={userData?.image ? (
                        <Avatar
                            src={userData?.image}
                            style={{ '--border-radius': '40px', '--size': '40px' }}
                        />
                    ) : (
                        <div style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '40px',
                            backgroundColor: '#075E54',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '20px',
                            color: '#fff'
                        }}>
                            {userData?.company_name?.charAt(0)}
                        </div>
                    )}
                    description={userData?.company_name}
                    clickable
                >
                    <strong>{userData?.user_name}</strong>
                </List.Item>
            </List>

            {/* Settings Section */}
            <List header='SETTINGS'>
                <List.Item extra='' clickable prefix={<UsergroupAddOutlined />} onClick={() => navigate('/team')}>Team</List.Item>
                <List.Item description='' clickable prefix={<DollarOutlined />} onClick={() => navigate('/payments')}>Payments</List.Item>
                <List.Item description='' clickable prefix={<MobileOutlined />}>Reset Password</List.Item>
            </List>

            {/* App Settings Section */}
            <List header='APP SETTINGS'>
                <List.Item prefix={<MoonOutlined />} extra={<Switch checked={enableDarkMode} onChange={handleDarkModeChange} />}>Dark Mode</List.Item>
                <List.Item prefix={<BellOutlined />} onClick={() => { }}>
                    Notifications
                </List.Item>
            </List>

            {/* Support Section */}
            <List header='SUPPORT'>
                <List.Item extra='' clickable prefix={<QuestionCircleOutlined />}>
                    Help center
                </List.Item>
                <List.Item description='' clickable prefix={<StarOutlined />}>
                    App feedback
                </List.Item>
            </List>

            {/* Logout Section */}
            <List header='&nbsp;'>
                <List.Item clickable  onClick={() => {setIsLoggedIn(false); localStorage.removeItem('redirectId')}} prefix={<LogoutOutlined />}>
                    Logout
                </List.Item>
            </List>
        </div>
    );
};

export default Profile;