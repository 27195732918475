import React from "react";
import { Typography } from 'antd';
import { Button } from "antd-mobile";
import { UploadOutline } from "antd-mobile-icons";
const { Paragraph, Title } = Typography;

const BulkAddContact = () => {
    return (
        <div className="addScreenMainDiv">
            <Title level={3}>Import Clients from CSV / XLSX</Title>
            <Paragraph>Select the CSV/XLSX file containing the list of contacts to import to your Connectby account.</Paragraph>
            <Button type='primary' block color='primary' size='large' ><UploadOutline /> Upload CSV / XLSX</Button>
            <br/>
            <Paragraph style={{fontSize:17}}>The CSV file should have 5 columns in the exact order below:</Paragraph>
            <div style={{textAlign:'left'}}>
                <table style={{border:'1px solid #000', width:'100%', padding:10}}>
                    <tr>
                        <th>Client Name</th>
                        <td>Max 80 characters</td>
                    </tr>
                    <tr>
                        <th>Country Code</th>
                        <td>Country code (e.g. 1)</td>
                    </tr>
                    <tr>
                        <th>Phone Number</th>
                        <td>Phone Number (e.g. 9876543210)</td>
                    </tr>
                    <tr>
                        <th>Email Address</th>
                        <td>Optional</td>
                    </tr>
                    <tr>
                        <th>Notes</th>
                        <td>Optional</td>
                    </tr>
                </table>
            </div>
            <br/>
            <Paragraph style={{fontSize:17}}>Please download the sample <a href = "https://connectby.io">CSV template</a> and follow the columns & formatting used.</Paragraph>
        </div>
    );
};

export default BulkAddContact;